/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import EFT from '../../assets/recentprojects/EFT.png';
import PORTAL from '../../assets/recentprojects/portal.png';


const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'Portal',
      description: `A platform for people to create and design clothes for the multiverse. 
      3D design are made trough spline 
      (APP on Heroku wait for it to boot)`,
      alter: 'Portal Project',
      image: `${PORTAL}`,
      url: 'https://portal-team1.herokuapp.com/'
    },
    { 
      id: 2,
      title: 'EFT Wiki',
      description: `Designed and developed a Webapp to find easy information about items in escape from tarkov 
      (APP on Heroku wait for it to boot)`,
      alter: 'EscapeFromTarkov Wiki ',
      image: `${EFT}`,
      url: 'http://eft-sorter.herokuapp.com/'
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper">
              <a className="__img_wrapper" href={project.url}>
              <img  src={ project.image } alt={ project.alter }/>
              </a>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={ project.id + '. ' + project.title } />
              </h3>
              <p className="description">
                { project.description }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
